// src/gatsby-plugin-theme-ui/index.js
//import { deep, sketchy, dark, swiss, base } from '@theme-ui/presets'
import { theme } from '../theme'


export default {
  ...theme,
  links: {
    nav: {
      px: 2,
      py: 1,
      textTransform: 'uppercase',
      letterSpacing: '0.1em',
    },
    navVertical: {
      variant: 'links.nav',
      display: 'inline-block'
    }
  }
}